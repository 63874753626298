<template>
    <div>
        <div class="detail">
            <div class="details-top" v-if="isPlay">
                <div class="top-img">
                    <img :src="gameInfo.logo" alt="">
                </div>
                <div class="top-title no-warp">
                    {{gameInfo.title}}
                </div>
                <div class="top-btn pointer" @click="()=>{isPlay = false}">
                    PLAY
                </div>                
            </div>
            <div class="gamewin" v-else>
                <iframe
                style="width:100%"
                :src="baseUrl+gameInfo.url"
                frameborder="0"></iframe>
            </div>
            <div class="details-description noselect">
                <div class="des-top">Description :</div>
                <div class="des-body warp-6">{{gameInfo.synopsis}}</div>
            </div>
            <div class="cont-st">
                <div class="cont-st-h noselect">More Games</div>
                <div class="card-list">
                    <div
                        class="card pointer"
                        v-for="items in gameList"
                        :key="items.id"
                        @click="goDetails(items)"
                    >
                        <img class="logo" :src="items.logo" alt="">
                        <img class="heart" src="@/assets/slices/heart1.png" alt="">
                    </div>
                    <span v-for="i in 6" :key="i+'s'"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { isMobile, scrollToTop } from "@/utils/api"
import { getGameById } from "@/api/games"

    export default {
        data() {
            return {
                value:4,
                gameList:[],
                gameInfo:{},
                spanNum:0,
                isPlay:true,
                baseUrl:''
            }
        },
        mounted() {
            let id = this.$route.query.id

            this.getGameInfo(id);

            if(isMobile()){
                this.spanNum = 3-2
            }else{
                this.spanNum = 11-2
            }

            // this.baseUrl = process.env.VUE_APP_BASE_GAME
            this.baseUrl = 'https://'+window.location.hostname

            scrollToTop(0);
        },
        methods: {
            async getGameInfo(id){
                let { game,similarGames } = await getGameById({id})
                this.gameInfo = game;
                this.gameList = similarGames;
            },

            goDetails(item){
                window.location.href = `${window.location.origin}/details?id=${item.id}`
            }
        },
    }
</script>

<style lang="less" scoped>
.noselect{
    -moz-user-select:none;/*火狐*/

    -webkit-user-select:none;/*webkit浏览器*/

    -ms-user-select:none;/*IE10*/

    -khtml-user-select:none;/*早期浏览器*/

    user-select:none;
}
.pointer{
    cursor: pointer;
}
.no-warp{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.warp-6{
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.detail{
    padding: 0 25px;
    box-sizing: border-box;
    .details-top{
        div{
            width: 260px;
            margin: 0 auto;
            text-align: center;
        }
        .top-img{
            width: 260px;
            height: 260px;
            border-radius: 24px;
            margin:24px auto 4px;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .top-title{
            margin: 8px auto;
            font-family: Inter, Inter;
            font-weight: bold;
            font-size: 18px;
            color: #273253;
            line-height: 23px;
            text-align: center;
            font-style: normal;
            text-transform: none;
        }
        .top-btn{
            width: 300px;
            height: 45px;
            background: #6357CC;
            border-radius: 10px;
            line-height: 45px;
            font-family: Inter, Inter;
            font-weight: bold;
            font-size: 16px;
            color: #FFFFFF;
            text-align: center;
            font-style: normal;
            text-transform: none;
        }
    }
    .gamewin{
        width: 100%;
        height: calc(100vh - 131px);
        iframe{
            width: 100%;
            height: 100%;
        }
    }
    .details-description{
        margin: 17px 0;
        .des-top{
            margin-bottom: 8px;
            font-family: Inter, Inter;
            font-weight: bold;
            font-size: 18px;
            color: #273253;
            line-height: 23px;
            text-align: left;
            font-style: normal;
            text-transform: none;
        }
        .des-body{
            font-family: Poppins, Poppins;
            font-weight: 400;
            font-size: 16px;
            color: #505050;
            line-height: 22px;
            text-align: left;
            font-style: normal;
            text-transform: none;
        }
    }
    .cont-st{
        box-sizing: border-box;
        margin-bottom: 25px;
        .cont-st-h{
            margin-bottom: 5px;
            font-family: Inter, Inter;
            font-weight: bold;
            font-size: 18px;
            color: #273253;
            line-height: 23px;
            text-align: left;
            font-style: normal;
            text-transform: none;
        }
        .card-list{
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            .card{
                width: 152px;
                height: 152px;
                border-radius: 8px;
                overflow: hidden;
                margin-bottom: 21px;
                position: relative;
                .logo{
                    width: 100%;
                    height: 100%;
                }
                .heart{
                    width: 17px;
                    height: 17px;
                    position: absolute;
                    top: 9px;right: 12px;
                }
            }
            span{
                width: 152px;
            }
        }
    }
}
</style>