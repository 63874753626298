<template>
    <div style="padding: 0 25px;box-sizing: border-box">
        <div class="game-body">
            <div class="body-h">
                <div class="noselect">{{ title }}</div>
            </div>
            <div class="card-list">
                <div
                    class="card pointer"
                    v-for="items in gameList"
                    :key="items.id"
                    @click="goDetails(items)"
                >
                    <img class="logo" :src="items.logo" alt="">
                    <img class="heart" src="@/assets/slices/heart1.png" alt="">
                </div>
                <span v-for="i in 5" :key="i+'s'"></span>
            </div>
        </div>
        <div class="game-body" v-if="showMore">
            <div class="body-h">
                <div class="noselect">more fun games</div>
            </div>
            <div class="card-list">
                <div
                    class="card pointer"
                    v-for="items in moreList"
                    :key="items.id"
                    @click="goDetails(items)"
                >
                    <img class="logo" :src="items.logo" alt="">
                    <img class="heart" src="@/assets/slices/heart1.png" alt="">
                </div>
                <span v-for="i in 5" :key="i+'s'"></span>
            </div>
        </div>
        <div class="class-info" v-if="!showMore">
            {{ intro }}
        </div>
    </div>
</template>

<script>
import eventBus from '@/utils/eventBus'
import { scrollToTop } from '@/utils/api'
import { getGameByKey, searchGame, getGame  } from "@/api/games"
import classInfo from "@/utils/classInfo"
export default {
    data() {
        return {
            title: '',
            gameList: [],
            funGame: [],
            moreList: [],
            intro: '',
            searchText: '',
            showMore: false
        }
    },
    mounted() {
        scrollToTop(0);
        eventBus.$on('searchgame',name=>{
            console.log(name)
            let {id,key} = name;
            this.getGameList(id,key)
        })
        let {id,key} = this.$route.query;
        this.getGameList(id,key);
    },
    methods: {
        searchGame(){
            this.getGameList('search',this.searchText);
        },
        goDetails(item){
            this.$router.push({
                path:'/details',
                query:{
                    id:item.id
                }
            })
        },
        async getGameList(id,key){
            let games = [];
            let title = '';
            if(id=='search'){
                games = await searchGame({q:key});
                title = `search found "${key}"`;
                this.moreList = await getGame({count:20});
                this.showMore = true;
            }else if(id=='more'){
                this.intro = classInfo.find(item => item.name === key).intro;
                if(key === 'All'){
                    games = await getGame();
                }else{
                    games = await getGameByKey({category:key});
                }
                title = key + ' games';
                this.showMore = false;
            }else{
                console.log(key);
            }
            this.gameList = games;
            this.title = title;
        }
    },
}
</script>

<style lang="less" scoped>
.noselect{
    -moz-user-select:none;/*火狐*/

    -webkit-user-select:none;/*webkit浏览器*/

    -ms-user-select:none;/*IE10*/

    -khtml-user-select:none;/*早期浏览器*/

    user-select:none;
}
.pointer{
    cursor: pointer;
}
.no-warp{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
@media (min-width: 768px){
    .search-cont{
        width: 327px;
        margin: 0 auto;
    }
}
.game-body{
    margin: 17px 0 14px;
    .body-h{
        margin-bottom: 5px;
        font-family: Inter, Inter;
        font-weight: bold;
        font-size: 18px;
        color: #273253;
        line-height: 23px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }
    .body-b{
        margin-top: 9px;
        overflow: auto;
        white-space: nowrap;
        @media (min-width: 768px){
            &::-webkit-scrollbar{
                height: 7px;
            }
            &::-webkit-scrollbar-thumb{
                border-radius: 10px;
                box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
                background: rgba(0,0,0,0.2);
            }
            &::-webkit-scrollbar-track{
                box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
                border-radius: 0;
                background: rgba(0,0,0,0.1);
            }
        }
    }
    .card-list{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        .card{
            width: 152px;
            height: 152px;
            border-radius: 8px;
            overflow: hidden;
            margin-bottom: 21px;
            position: relative;
            .logo{
                width: 100%;
                height: 100%;
            }
            .heart{
                width: 17px;
                height: 17px;
                position: absolute;
                top: 9px;right: 12px;
            }
        }
        span{
            width: 152px;
        }
    }
}
.class-info{
    padding: 8px 0px;
    font-family: Inter, Inter;
    font-weight: 400;
    font-size: 16px;
    color: #505050;
    line-height: 22px;
    text-align: left;
    font-style: normal;
    text-transform: none;
}

</style>