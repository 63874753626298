<template>
    <div>
        <div class="header">
            <div class="header-top">
                <img @click="goIndex" class="logo pointer" src="@/assets/slices/logo.png" alt="">
                <img class="class pointer" src="@/assets/slices/class.png" alt="" @click="showMenu()">
            </div>
            <div class="search-body" v-if="!isMenu&&$route.path!=='/details'">
                <input @keydown.enter="searchGame" type="text" placeholder="Search" v-model="searchText">
                <img class="pointer" @click="searchGame" src="@/assets/slices/search.png" alt="">
            </div>
        </div>
        <navMenu v-show="isMenu"></navMenu>
    </div>
</template>

<script>
import navMenu from "@/components/navMenu";
import eventBus from '@/utils/eventBus'

    export default {
        components:{
            navMenu
        },
        data() {
            return {
                isMenu: false,
                searchText: ''
            }
        },
        mounted() {
            eventBus.$on('showmenu',i=>{
                this.isMenu = i;
            })
        },
        methods: {
            showMenu(){
                this.isMenu = !this.isMenu;
            },
            goIndex(){
                if(this.$route.path !== '/'){
                    this.$router.push('/');
                }else{
                    this.$router.go(0)
                }
            },
            searchGame(){
                if(this.$route.path !== '/selectGame'){
                    this.$router.push({
                        path: '/selectGame',
                        query: {
                            id: 'search',
                            key: this.searchText
                        }
                    });
                }else{
                    eventBus.$emit('searchgame',{
                        id: 'search',
                        key: this.searchText
                    });
                }
            }
        },
    }
</script>

<style lang="less" scoped>
@media (min-width: 768px) {
  .header{
    width: 1200px;
  }
}
@media (max-width: 768px) {
  .header{
    width: 100%;
  }
}
.pointer{
    cursor: pointer;
}
.header{
    position: fixed;
    top: 0;
    z-index: 99999;
    .header-top{
        padding: 0 21px 0 17px;
        box-sizing: border-box;
        height: 97px;
        background: linear-gradient( 135deg, #8F67E8 0%, #6357CC 100%);
        border-radius: 0px 0px 0px 35px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logo{
            width: 185px;
            height: 46px;
        }
        .class{
            width: 21px;
            height: 18px;
        }
    }
    .search-body{
        width: 325px;
        height: 50px;
        background: #FFFFFF;
        border-radius: 8px 8px 8px 8px;
        margin: -16px auto 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 15px;
        box-sizing: border-box;
        img{
            width: 15px;
            height: 15px;
            margin-right: 16px;
        }
        input{
            &::placeholder {
                font-family: Rubik, Rubik;
                font-weight: 400;
                font-size: 20px;
                color: #636363;
                text-align: left;
                font-style: normal;
                text-transform: none;
            }
            color: #000;
            font-size: 20px;
            border: none;
            outline: none;
        }
    }
}
</style>