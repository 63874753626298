<template>
    <div>
        <adContent></adContent>
        <div class="game-List"
             v-for="(item,key) in gameList"
             :key="key"
        >
            <div class="list-title">
                <div class="key noselect">{{key}}</div>
                <div class="more pointer" @click="goSelect(item[0].key_name)">MORE</div>
            </div>
            <div class="card-list">
                <div
                    class="card pointer"
                    v-for="items in item"
                    :key="items.id"
                    @click="goDetails(items)"
                >
                    <img class="logo" :src="items.logo" alt="">
                    <img class="heart" src="@/assets/slices/heart1.png" alt="">
                </div>
                <span v-for="i in 5" :key="i+'s'"></span>
            </div>
        </div>
        <div class="game-blurb noselect">
            Welcome to {{blurbUrl}} Here, various free online games can be played directly in your browser without any downloads. Our collection of online games will provide you with hours ofentertainment. Invite your friends over and enjoy these popular games together. From classic arcade games to car games, cricket games, puzzles, mahjong, and more, there's something for everyone in our game categories. We have carefully selected the best online games, and we review and add new games every day, so you can learn new skills as you play! Let's start playing
        </div>
    </div>
</template>

<script>
import {
    getGameList,
} from "@/api/games";
import adContent from "@/components/adContent/index.vue";
    export default {
        components: {
            adContent
        },
        data() {
            return {
                gameList: {},
                classList: [],
                sClass: '',
                carouselList:[],
                blurbUrl: process.env.VUE_APP_BASE_GAME
            }
        },
        mounted() {
            this.getList();
        },
        methods: {
            goDetails(item){
                this.$router.push({
                    path:'/details',
                    query:{
                        id:item.id
                    }
                })
            },
            goSelect(key){
                console.log(key)
                this.$router.push({
                    path: '/selectGame',
                    query: {
                        id: 'more',
                        key
                    }
                })
            },
            async getList(){
                this.gameList = await getGameList();
            },
        },
    }
</script>

<style lang="less" scoped>
.noselect{
    -moz-user-select:none;/*火狐*/

    -webkit-user-select:none;/*webkit浏览器*/

    -ms-user-select:none;/*IE10*/

    -khtml-user-select:none;/*早期浏览器*/

    user-select:none;
}
.pointer{
    cursor: pointer;
}
.no-warp{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.game-List{
    padding: 25px;
    box-sizing: border-box;
    .list-title{
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        .key{

            font-family: Rubik, Rubik;
            font-weight: 500;
            font-size: 18px;
            color: #313131;
            text-align: left;
            font-style: normal;
            text-transform: none;
        }
        .more{
            font-family: Rubik, Rubik;
            font-weight: 500;
            font-size: 14px;
            color: #6155CC;
            text-align: left;
            font-style: normal;
            text-transform: none;
        }
    }
    .card-list{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        .card{
            width: 152px;
            height: 152px;
            border-radius: 8px;
            overflow: hidden;
            margin-bottom: 21px;
            position: relative;
            .logo{
                width: 100%;
                height: 100%;
            }
            .heart{
                width: 17px;
                height: 17px;
                position: absolute;
                top: 9px;right: 12px;
            }
        }
        span{
            width: 152px;
        }
    }
}
.game-blurb{
    border-radius: 10px;
    padding: 8px 25px;
    box-sizing: border-box;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    line-height: 22px;
    text-align: left;
    font-style: normal;
}
</style>